/* eslint-disable default-param-last */
import { Reducer } from '@reduxjs/toolkit';
import { SwapState, SwapTypes } from '../types';

import * as Actions from '../actions/swap';
import { tokenList } from '../../components/token/HeroSection/swap/helpers';

const INITIAL_STATE: SwapState = {
  stackPrice: 0,
  isSettingsOpen: false,
  isTokenSelectOpen: false,
  isErrorOpen: false,
  loading: false,
  fromTokenAmount: null,
  toTokenAmount: null,
  fromTokenPrice: 0,
  expectedOutput: 0,
  tokenOptions: tokenList['Polygon PoS'],
  tokenSelected: tokenList['Polygon PoS'][0],
  stackAddress: '',
  networkSelected: {
    id: 137,
    title: 'Polygon PoS',
    icon: 'polygon',
  },
  slippageAmount: 0.5,
  errorMessage: '',
  isSummaryOpen: false,
  isWalletModalOpen: false,
  estimatedGas: null,
  tokenBalance: undefined,
};

const reducer: Reducer<SwapState> = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SwapTypes.SET_SETTINGS_STATUS:
      return { ...state, isSettingsOpen: payload.value };

    case SwapTypes.SET_TOKEN_SELECT_STATUS:
      return { ...state, isTokenSelectOpen: payload.value };

    case SwapTypes.SET_ERROR_STATUS:
      return { ...state, isErrorOpen: payload.value };

    case SwapTypes.SET_LOADING:
      return { ...state, loading: payload.value };

    case SwapTypes.SET_STACK_PRICE:
      return { ...state, stackPrice: payload.value };

    case SwapTypes.SET_FROM_TOKEN_AMOUNT:
      return { ...state, fromTokenAmount: payload.value };

    case SwapTypes.SET_TO_TOKEN_AMOUNT:
      return { ...state, toTokenAmount: payload.value };

    case SwapTypes.SET_FROM_TOKEN_PRICE:
      return { ...state, fromTokenPrice: payload.value };

    case SwapTypes.SET_EXPECTED_OUTPUT:
      return { ...state, expectedOutput: payload.value };

    case SwapTypes.SET_TOKEN_OPTIONS:
      return { ...state, tokenOptions: payload.value };

    case SwapTypes.SET_TOKEN_SELECTED:
      return { ...state, tokenSelected: payload.value };

    case SwapTypes.SET_STACK_ADDRESS:
      return { ...state, stackAddress: payload.value };

    case SwapTypes.SET_NETWORK_SELECTED:
      return { ...state, networkSelected: payload.value };

    case SwapTypes.SET_SLIPPAGE_AMOUNT:
      return { ...state, slippageAmount: payload.value };

    case SwapTypes.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: payload.value };

    case SwapTypes.SET_SUMMARY_STATUS:
      return { ...state, isSummaryOpen: payload.value };

    case SwapTypes.SET_WALLET_MODAL_STATUS:
      return { ...state, isWalletModalOpen: payload.value };

    case SwapTypes.SET_ESTIMATED_GAS:
      return { ...state, estimatedGas: payload.value };

    case SwapTypes.SET_TOKEN_BALANCE:
      return { ...state, tokenBalance: payload.value };

    case SwapTypes.RESET_STATE:
      return {
        ...state,
        stackPrice: 0,
        isSettingsOpen: false,
        isTokenSelectOpen: false,
        isErrorOpen: false,
        loading: false,
        fromTokenAmount: null,
        toTokenAmount: null,
        fromTokenPrice: 0,
        expectedOutput: 0,
        slippageAmount: 0.5,
        errorMessage: '',
        isSummaryOpen: false,
        isWalletModalOpen: false,
        estimatedGas: null,
        tokenBalance: null,
      };

    default:
      return state;
  }
};

export const SwapActions = Actions;
export default reducer;
